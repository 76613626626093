.text-white {
    color: #fff;
}

.medium {
    font-size: 120%;
}

.no-botom-margin {
    margin-bottom: 0px;
}

.navbar-brand img {
    max-height: 60px;
}

.navbar-static-top {
    height: 85px;
}

.sidebar {
    margin-top: 85px;
    width: 200px;
}

#page-wrapper {
    margin-left: 200px;
}

.go-to-location.go-to-location-top {
    bottom: 70px;
}

.go-to-location.go-to-location-bottom {
    bottom: 20px;
}

.date-expired {
    color: red !important;
}

.form-group .form-control-feedback.form-control-feedback-clickable {
    pointer-events: initial; /* or - auto // or -  unset  */
}

.table-responsive {
    overflow-x: hidden;
}

/* Vertically centre modal */
.modal-centre {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#dataTable-documents tbody tr {
    height: 70px;
}

.form-group-datepicker {
    width: 130px;
}

/* To center checkbox in table; text-center of parent td doesn't properly centre */
input[type="checkbox"].document-archived {
    margin-left: 16px;
}

.panel-large .panel-heading {
    height: 130px;
}

/*https://stackoverflow.com/a/18568997/171703*/
.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
}

.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";
}

.panel-compact {
    margin-bottom: 0;
}

.panel-primary a:hover,
.panel-primary a:focus {
    color: #165973;
}

.panel-primary .panel a:hover,
.panel-primary .panel a:focus {
    color: #23527c;
}
